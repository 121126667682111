<template>
  <!--begin::Basic info-->
  <div class="card mb-5 mb-xl-10">
    <!--begin::Card header-->
    <div class="card-header border-0 bg-default">
      <!--begin::Card title-->
      <div class="card-title m-0 d-flex justify-content-between w-100">
        <!--begin::Navs-->
        <div class="d-flex overflow-auto h-55px mb-5">
          <ul
            class="
              nav nav-stretch nav-line-tabs nav-line-tabs-2x
              border-transparent
              fs-5
              fw-bolder
              flex-nowrap
            "
          >
            <!--begin::Nav item-->
            <li class="nav-item">
              <router-link
                to="/movement-map/map"
                class="nav-link text-active-white text-warning me-6"
                active-class="active"
              >
                Peta Pergerakan
              </router-link>
            </li>
            <!--end::Nav item-->
            <!--begin::Nav item-->
            <li class="nav-item">
              <router-link
                class="nav-link text-active-white text-warning me-6"
                to="/movement-map/chart"
                active-class="active"
              >
                Grafik Pergerakan
              </router-link>
            </li>
            <!--end::Nav item-->
          </ul>
        </div>
        <!--begin::Navs-->
      </div>
      <!--end::Card title-->
    </div>
    <!--begin::Card header-->

    <!--begin::Content-->
    <div id="kt_account_profile_details" class="collapse show">
      <!--begin::Card body-->
      <div class="card-body border-top p-0">
        <router-view />
      </div>
      <!--end::Card body-->
    </div>
    <!--end::Content-->
  </div>
  <!--end::Basic info-->
</template>

<script>
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
export default {
  name: "CalegMap-Index",
  setup() {
    setCurrentPageTitle("Pergerakan Caleg");
  },
};
</script>
